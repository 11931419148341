import { ContactType, DocumentType } from '@innedit/innedit-type';
import { ContactData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import Icon from '~/components/Icon';
import { ListItemProps } from '~/containers/Espace/List/props';
import IconUserCircle from '~/icons/UserCircle';

import Item from './index';

const ListItemContact: FC<ListItemProps<ContactType, ContactData>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<ContactType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <Item
      description={doc.phone}
      doc={doc}
      icon={doc.userId ? <Icon value={IconUserCircle} /> : undefined}
      index={index}
      label={doc.label}
      onClick={onClick}
      subLabel={doc.companyName}
    />
  );
};

export default ListItemContact;
