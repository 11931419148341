import compact from 'lodash/compact';

import { ActionProps } from '~/components/Actions/props';
import IconAddressBook from '~/icons/AddressBook';
import IconAddressCard from '~/icons/AddressCard';
import IconUsers from '~/icons/Users';

const useContact = ({
  espaceId,
  pathname,
}: {
  espaceId: string;
  pathname: string;
}): (ActionProps | ActionProps[])[] | undefined => {
  // const [espace, setEspace] = useState<DocumentType<EspaceType>>();
  //
  // useEffect(() => {
  //   let isMounted = true;
  //
  //   const espaceModel = new EspaceData();
  //   espaceModel.watchById(espaceId, snapshot => {
  //     if (isMounted) {
  //       setEspace(snapshot);
  //     }
  //   });
  //
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [espaceId]);
  //
  // if (!espace) {
  //   return undefined;
  // }

  const isActive = (to: string, strict?: boolean): string | undefined => {
    if (strict) {
      return `/espaces/${espaceId}/contacts${to}` === pathname
        ? 'is-active'
        : undefined;
    }

    return pathname.slice(0, `/espaces/${espaceId}/contacts${to}`.length) ===
      `/espaces/${espaceId}/contacts${to}`
      ? 'is-active'
      : undefined;
  };

  return compact([
    {
      className: isActive(``, true),
      icon: IconAddressBook,
      label: 'Contacts',
      to: `/espaces/${espaceId}/contacts`,
    },
    {
      className: isActive(`/profiles`),
      icon: IconAddressCard,
      label: 'Profils',
      to: `/espaces/${espaceId}/contacts/profiles`,
    },
    {
      className: isActive(`/groups`),
      icon: IconUsers,
      label: 'Groupes',
      to: `/espaces/${espaceId}/contacts/groups`,
    },
    // {
    //   className: isActive(`/companies`),
    //   icon: IconUsers,
    //   label: 'Entreprises',
    //   to: `/espaces/${espaceId}/contacts/companies`,
    // },
  ]);
};

export default useContact;
